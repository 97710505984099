<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :label="label"
        :value="formattedDate"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        color="actionButton"
        event-color="actionButton"
        style="
          color: black;
          margin-top: 20px;
          font-size: 16px !important;
          font-weight: 600 !important;
        "
      ></v-text-field>
    </template>
    <v-card class="ma-0 pa-0">
      <v-date-picker
        v-model="dateValue"
        :type="type ?? 'month'"
        no-title
        @input="menu = false"
        reactive
        scrollable
        color="actionButton"
      ></v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: "DateInput",
  props: ["value", "label", "disabled", "type"],
  components: {},

  computed: {
    dateValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedDate: {
      get() {
        if (!this.value) return ""; // Return empty string if value is not set
        return moment(this.value).format("MM/YYYY");
      },
      // if (!this.value) return ""; // Return empty string if value is not set
      // return format(new Date(this.value), "dd/MM/yyyy");
    },
  },

  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style></style>
