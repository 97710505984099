var stmarieApi = {
  // async ctb(filCod) {
  //   var myHeaders = {};

  //   var raw = JSON.stringify({
  //     auth_key: "HP!9G!7uVv5lJ7&fzCAryW18r4obhonn$KzE*cs6nr51Pw6^&H",
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   let ans = await fetch(
  //     `https://app.stmarie.com.br/ctb_006_filial_${filCod}`,
  //     requestOptions
  //   )
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((json) => {
  //       return json;
  //     });

  //   return ans;
  // },

  async ctb(filial) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stmarie/ctb_006`;
    var body = JSON.stringify({
      filial,
    });

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async ctbV2(filial, anomes, lastDay) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stmarie/ctb_006_v2`;
    var body = JSON.stringify({
      filial,
      anomes,
      lastDay,
    });

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default stmarieApi;
