import moment from "moment";
function moneyFormat(value) {
  return new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  }).format(value || 0);
}

export default moneyFormat;

// Vue.directive("money-format", (el, { value }) => {
//   //console.log(value);
//   el.innerHTML = new Intl.NumberFormat("pt-BR", {
//     currency: "BRL",
//     style: "currency",
//     minimumFractionDigits: 2,
//   }).format(value || 0);
// });
