<template>
  <v-container fluid style="">
    <v-container
      v-if="user && user.cmn025 && user.profileId == 3"
      fluid
      style="display: flex"
    >
      <v-row>
        <h2 style="margin-left: 50px" class="primary--text">
          {{ user.cmn025.dpeNomFantasia }}
        </h2></v-row
      >
    </v-container>

    <v-container v-if="!simpleProcessesView" fluid class="dashboard-width">
      <v-row class="pa-2">
        <v-col cols="12" lg="3" md="5" sm="5" class="pa-0 ma-0">
          <v-container class="ma-0 pa-0">
            <v-card class="ma-0 mt-6"
              ><v-card-title style="font-color: #6613d0!"
                ><span>Filtros</span>
              </v-card-title>
              <v-card-text class="mt-4">
                <AutocompleteSelect
                  label="Encomendante"
                  :items="optionsEncomendantes"
                  v-model="chipsEncomendantes"
                  v-if="!(user && user.cmn025 && user.profileId == 3)"
                ></AutocompleteSelect>

                <AutocompleteSelect
                  label="Exportador"
                  :items="optionsExportadores"
                  v-model="chipsExportadores"
                ></AutocompleteSelect>

                <v-select
                  class="mx-5"
                  :items="optionsShipped"
                  v-model="filterShipped"
                  item-text="text"
                  item-value="value"
                  style="
                    color: black;
                    font-size: 16px !important;
                    font-weight: 600 !important;
                  "
                >
                </v-select>
                <v-container>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    class="mt-4"
                  >
                    <template class="mt-3" v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Início"
                        v-model="dateFilterStart"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        color="actionButton"
                        event-color="actionButton"
                        style="
                          color: black;
                          font-size: 16px !important;
                          font-weight: 600 !important;
                        "
                      ></v-text-field>
                    </template>
                    <v-card class="ma-0 pa-0">
                      <v-date-picker
                        v-model="dateFilterStart"
                        type="date"
                        no-title
                        @input="menu = false"
                        reactive
                        scrollable
                        color="actionButton"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </v-container>
                <v-container>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    class="mt-3"
                  >
                    <template class="mt-3" v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Fim"
                        v-model="dateFilterEnd"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        color="actionButton"
                        event-color="actionButton"
                        style="
                          color: black;
                          font-size: 16px !important;
                          font-weight: 600 !important;
                        "
                      ></v-text-field>
                    </template>
                    <v-card class="ma-0 pa-0">
                      <v-date-picker
                        v-model="dateFilterEnd"
                        type="date"
                        no-title
                        @input="menu2 = false"
                        reactive
                        scrollable
                        color="actionButton"
                      ></v-date-picker>
                    </v-card>
                  </v-menu>
                </v-container>
                <v-text-field
                  class="mx-5"
                  label="Referência do processo"
                  v-model="search"
                  v-if="user?.role?.id == 5"
                >
                </v-text-field>

                <v-alert
                  :value="alert"
                  prominent
                  text
                  type="info"
                  icon="mdi-information-outline"
                  @click="alert = false"
                >
                  Favor selecionar ao menos um filtro Encomendante ou Exportador
                </v-alert>
                <v-btn class="primary" @click="getProcesses" :disabled="loading"
                  >Buscar
                  <v-progress-circular
                    v-show="loading"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn> </v-card-text
            ></v-card>
          </v-container>
        </v-col>

        <v-col cols="12" lg="9" md="7" sm="7" class="pa-0 ma-0">
          <v-container fluid class="ma-0 pa-4">
            <v-container v-if="mounting">
              <v-progress-circular
                indeterminate
                color="blue"
              ></v-progress-circular>
            </v-container>
            <v-row v-if="processes.length == 0 || mounting">
              <v-col></v-col>
              <v-col>
                <v-container fluid>
                  <v-img src="@/assets/img/banner4.png" />
                </v-container>
              </v-col>
              <v-col></v-col>
            </v-row>

            <v-container fluid class="ma-0 pa-0">
              <v-row class="mt-0" v-show="processes.length > 0 && !mounting">
                <v-col cols="12" xl="6" lg="6" class="ma-0">
                  <v-card
                    class="mx-auto mt-0"
                    style="background-color: white; height: 100%"
                  >
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="4" xl="2" lg="3">
                          <div
                            style="
                              width: 75px;
                              height: 75px;
                              border-radius: 100%;
                              background-color: #229dce;
                              justify-content: center;
                              display: flex;
                            "
                          >
                            <v-icon class="text-h1 white--text"
                              >mdi-chart-bar</v-icon
                            >
                          </div>
                        </v-col>
                        <v-col cols="8" xl="10" lg="9">
                          <h4>TOTAL DE PROCESSOS</h4>
                          <p class="mt-4 text-highlight">
                            {{ countProcesses }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xl="6" lg="6" class="ma-0">
                  <v-card
                    class="mx-auto mt-0"
                    style="background-color: white; height: 100%"
                  >
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="4" xl="2" lg="3">
                          <div
                            style="
                              width: 75px;
                              height: 75px;
                              border-radius: 100%;
                              background-color: #37a7b6;
                              justify-content: center;
                              display: flex;
                            "
                          >
                            <v-icon class="text-h1 white--text"
                              >mdi-currency-usd</v-icon
                            >
                          </div>
                        </v-col>
                        <v-col cols="8" xl="10" lg="9">
                          <h4>VALOR FOB</h4>

                          <p class="mt-4 text-highlight">
                            <span v-big-money-format="amountFob"> </span>
                            <span> {{ currencyCode }}</span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row> </v-row>
              <v-row v-show="processes.length > 0 && !mounting">
                <v-col cols="12">
                  <v-card
                    class="mx-auto my-0"
                    style="height: 100%; background-color: white"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col cols="4" xl="1" lg="1">
                          <div
                            style="
                              width: 75px;
                              height: 75px;
                              border-radius: 100%;
                              background-color: #a5e348;
                              justify-content: center;
                              display: flex;
                            "
                          >
                            <v-icon class="text-h1 white--text"
                              >mdi-handshake-outline</v-icon
                            >
                          </div>
                        </v-col>

                        <v-col cols="8" xl="11" lg="11">
                          <h4>EXPORTADORES</h4>

                          <p class="mt-4 text-highlight">
                            <span v-big-money-format="amountFob"> </span>
                            <span> {{ currencyCode }}</span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <div v-for="(item, index) in exportersAcc">
                        <ProgressLinearInfo
                          :value="item.total"
                          :max="amount"
                          :label1="item.name"
                          :label2="`${formatUsd(item.total)} ${currencyCode}`"
                          :color="colors[index]"
                        ></ProgressLinearInfo>
                        <v-divider></v-divider>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      class="dashboard-width"
      v-if="processes.length > 0 && !mounting"
    >
      <v-row v-if="!simpleProcessesView" class="pa-2">
        <v-col cols="12" style="" class="mt-4">
          <v-card class="mx-auto my-0" style="height: 100%">
            <v-card-text
              ><h4>FOB {{ currencyCode }}</h4>
            </v-card-text>
            <v-card-text>
              <bar-chart3
                chart-id="mychart"
                ref="mychart0"
                :chart-data="vlrDolarFinalChartData?.dataCollection"
                :chart-options="vlrDolarFinalChartData?.options"
              ></bar-chart3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!simpleProcessesView" class="pa-2">
        <v-col cols="12" md="4">
          <v-card style="height: 100%">
            <v-card-title><h4>TOP 5 PRODUTOS</h4> </v-card-title>
            <v-card-text class="pa-6">
              <doughnut-chart
                aspectRatio="0.8"
                :chartData="chartDataProdutos"
                :height="300"
                legend-position="bottom"
              ></doughnut-chart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card style="height: 100%">
            <v-card-title><h4>TOP 5 EXPORTADORES </h4> </v-card-title>
            <v-card-text class="pa-6">
              <doughnut-chart
                aspectRatio="0.8"
                :chartData="chartDataExportadores"
                :height="300"
                legend-position="bottom"
              ></doughnut-chart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card style="height: 100%">
            <v-card-title><h4>CANAL</h4> </v-card-title>
            <v-card-text class="pa-6">
              <doughnut-chart
                aspectRatio="0.8"
                :chartData="chartDataCanais"
                :height="300"
                legend-position="bottom"
              ></doughnut-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!simpleProcessesView" class="pa-2 my-0">
        <v-col cols="12" md="7" v-if="processes.length > 0">
          <v-card style="height: 100%; align-items: bottom">
            <v-card-title><h4>MAPA DE ORIGEM DA IMPORTAÇÃO</h4> </v-card-title>

            <v-card-text class="pa-0 mt-8" style="bottom: 10px; margin: auto">
              <v-container
                style="margin: auto"
                fluid
                v-if="countriesAcc && countriesAcc.length > 0"
                class="pa-0"
              >
                <Map :currencyCode="currencyCode"></Map>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card style="height: 100%">
            <v-card-title><h4>LOCAIS DE DESTINO</h4> </v-card-title>
            <v-card-text>
              <div v-for="(item, index) in armazemBlAcc">
                <ProgressLinearInfo
                  :value="item.total"
                  :max="getMax(armazemBlAcc)"
                  :label1="item.name"
                  :label2="`${formatUsd(item.total)} ${currencyCode}`"
                  :color="colors[index]"
                ></ProgressLinearInfo>
                <v-divider></v-divider>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!simpleProcessesView" class="pa-2 my-0" style="height: 100%">
        <v-col cols="12" md="3" style="">
          <v-card style="height: 100%" class="ma-0 pa-0">
            <v-card-title>
              <h4>A EMBARCAR</h4>
              <h4>/EM TRÂNSITO</h4>
            </v-card-title>
            <v-card-text class="ma-0">
              <doughnut-chart
                :chartData="chartDataEmbarque"
                :height="300"
                legend-position="bottom"
                aspectRatio="0.8"
                show-absolute="true"
              ></doughnut-chart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" style="min-height: 100%">
          <v-card
            v-if="processes.length > 0"
            style="min-height: 100%"
            class="ma-0 pa-0"
          >
            <v-card-title
              ><h4>FRETE ({{ currencyCode }})</h4>
            </v-card-title>
            <v-card-text style="height: 100%">
              <bar-chart
                class="ma-0"
                :chart-data="monthFreteChartData.dataCollection"
                :chart-options="monthFreteChartData.options"
                :height="350"
              ></bar-chart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" style="min-height: 100%">
          <v-card style="height: 100%" class="ma-0 pa-0">
            <v-card-title><h4>ARMADOR</h4> </v-card-title>
            <v-card-text>
              <div v-for="(item, index) in armadorAcc">
                <ProgressLinearInfo
                  :value="item.total"
                  :max="getMax(armadorAcc)"
                  :label1="item.name"
                  :label2="`CONTAINERS: ${item.total}`"
                  :color="colors[index]"
                ></ProgressLinearInfo>
                <v-divider></v-divider>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pa-2 my-0">
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-toolbar dark color="primary" style="z-index: 3"
              ><h4>Processos</h4>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="exportToExcel"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white"> mdi-download </v-icon></v-btn
                  >
                </template>
                <span>Exportar para excel</span>
              </v-tooltip>
            </v-toolbar>

            <v-data-table
              :headers="processesHeaders"
              :items="processes"
              item-key="name"
              class="custom-table"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :server-items-length.sync="serverItemsLength"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
              }"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              @update:items-per-page="updateRowsPerPage"
              @update:page="updatePage"
              ref="dataTable"
              :hide-default-footer="simpleProcessesView"
            >
              <template v-slot:header.id="{ header }">
                <div>
                  {{ ` ${header.text}` }}

                  <div
                    fluid
                    style="
                      background-color: white;
                      margin: 10px;

                      position: absolute;
                      z-index: 2;
                      border-right: 0.1px solid #dddddd;

                      border-color: #dddddd;
                      height: 93px;
                      width: 326px;
                      margin-top: -35px;
                      margin-left: -11px;
                      padding: 0px;
                      display: flex;
                      border-bottom: none;
                    "
                  >
                    <div
                      fluid
                      style="
                        background-color: white;
                        border-bottom: 0.1px solid #dddddd;
                        border-left: 0.1px solid #dddddd;
                        height: 100%;
                        width: 86px;
                        margin: 0px;
                        padding-top: 6px;
                        border-width: 0.01px;
                        border-color: #dddddd;
                        border-bottom: none;
                      "
                    >
                      <span style="margin: 5px"> Detalhes</span>
                    </div>
                    <div
                      fluid
                      style="
                        background-color: white;
                        border-bottom: 0.1px solid #dddddd;
                        border-left: 0.1px solid #dddddd;
                        height: 100%;
                        width: 117px;
                        margin: 0px;
                        padding: 6px;
                        border-width: 0.01px;
                        border-color: #dddddd;
                        border-bottom: none;
                      "
                    >
                      <span style="margin-left: 5px; margin-top: 10px">
                        Referência</span
                      >
                      <p style="margin-left: 5px">interna</p>
                    </div>
                    <div
                      fluid
                      style="
                        background-color: white;
                        border-bottom: 0.1px solid #dddddd;
                        border-left: 0.1px solid #dddddd;
                        height: 100%;
                        width: 114px;
                        margin: 0px;
                        padding: 6px;
                        border-width: 0.01px;
                        border-color: #dddddd;
                        border-right: none;
                        border-bottom: none;
                      "
                    >
                      <span style="margin-left: 5px; margin-top: 10px">
                        Referência
                      </span>
                      <p style="margin-left: 5px">cliente</p>
                    </div>
                  </div>
                </div>
              </template>

              <template
                v-slot:item.id="{ item }"
                style="background-color: #6613d0"
              >
                <div
                  fluid
                  elevation="1"
                  style="
                    background-color: white;
                    display: flex;
                    margin: 10px;
                    border: 0.1px solid #dddddd;
                    position: absolute;
                    height: 50px;
                    width: 326px;
                    margin-top: -24.5px;
                    margin-left: -17px;
                    padding: 0px;
                    z-index: 100;
                    border-width: 0.01px;
                    border-color: #dddddd;
                  "
                >
                  <div
                    fluid
                    style="
                      border-bottom: 0.1px solid #dddddd;
                      border-left: 0.1px solid #dddddd;
                      height: 50px;
                      width: 86px;
                      margin: 0px;
                      padding: 0px;
                      border-width: 0.01px;
                      border-color: #dddddd;
                    "
                  >
                    <v-icon
                      color="#08243c"
                      style="font-size: 30px; margin: 5px"
                      @click="goToPage(item.PRI_COD)"
                      >mdi-magnify</v-icon
                    >
                  </div>

                  <div
                    fluid
                    style="
                      border-bottom: 0.1px solid #dddddd;
                      border-left: 0.1px solid #dddddd;
                      height: 50px;
                      width: 116px;
                      margin: 0px;
                      padding: 0px;
                      border-width: 0.01px;
                      border-color: #dddddd;
                    "
                  >
                    <span style="margin: 3px">
                      {{ item.PRI_ESP_REFCLIENTE }}</span
                    >
                  </div>

                  <div
                    fluid
                    style="
                      border-bottom: 0.1px solid #dddddd;
                      border-left: 0.1px solid #dddddd;
                      height: 50px;
                      width: 120px;
                      margin: 0px;
                      padding: 0px;
                      border-width: 0.01px;
                      border-color: #dddddd;
                      border-right: none;
                    "
                  >
                    <span style="margin: 3px">
                      {{ item.PRI_ESP_REFERENCIA }}</span
                    >
                  </div>
                </div>
              </template>

              <template v-slot:item.PRI_VLD_STATUS="{ item }">
                <span
                  :style="getStyleProcessStatus(item.PRI_VLD_STATUS, item)"
                  >{{ getTextProcessStatus(item.PRI_VLD_STATUS, item) }}</span
                >
              </template>

              <template v-slot:item.CANAL="{ item }">
                <v-icon v-if="item.CANAL == 'VERMELHO'" color="red">
                  mdi-circle
                </v-icon>
                <v-icon v-if="item.CANAL == 'VERDE'" color="green">
                  mdi-circle
                </v-icon>
                <v-icon v-if="item.CANAL == 'AMARELO'" color="#FFD700">
                  mdi-circle
                </v-icon>
                {{ item.CANAL }}
              </template>

              <template v-slot:item.PRI_ESP_REFCLIENTE="{ item }">
                <div style="width: 202px"></div
              ></template>

              <template v-slot:item.INV_ESP_PO="{ item }">
                <div style="width: 120px"></div>
              </template>

              <template v-slot:item.REGISTRO_DI="{ item }">
                <span
                  v-if="item.REGISTRO_DI"
                  v-date-format="item.REGISTRO_DI"
                ></span>
              </template>

              <template v-slot:item.PRI_DTA_ABERTURA="{ item }">
                <span v-date-format="item.PRI_DTA_ABERTURA"></span>
              </template>

              <template v-slot:item.DTA_PREV_EMBARQUE_BL="{ item }">
                <span v-date-format="item.DTA_PREV_EMBARQUE_BL"></span>
              </template>

              <template v-slot:item.DTA_EMBARQUE_BL="{ item }">
                <span v-date-format="item.DTA_EMBARQUE_BL"></span>
              </template>

              <template v-slot:item.DTA_PREV_CHEGADA_BL="{ item }">
                <span v-date-format="item.DTA_PREV_CHEGADA_BL"></span>
              </template>

              <template v-slot:item.DTA_CHEGADA_BL="{ item }">
                <span v-date-format="item.DTA_CHEGADA_BL"></span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
//import Chartist from "chartist";
import en from "vuetify/lib/locale/en";
import { mapState, mapMutations } from "vuex";
import firestoreApi from "../../../../libraries/firestore/firestoreApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import DatePicker from "../../components/fields/DatePicker.vue";
import ProgressLinearInfo from "../../components/fields/ProgressLinearInfo.vue";
import ChartCard from "../../components/charts/ChartCard.vue";
import PieChart from "../../components/charts/PieChart.vue";
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
import { variableDeclaration } from "@babel/types";
import Map from "../../components/maps/Map.vue";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import orderers from "../../../../libraries/data/orderers";
import countries from "../../../../libraries/data/countries";
import BarChart from "../../components/charts/BarChart2.vue";
import BarChart3 from "../../components/charts/BarChart3.vue";
import { read, utils, writeFile } from "xlsx";

export default {
  name: "Dashboard",
  components: {
    DatePicker,
    ProgressLinearInfo,
    ChartCard,
    PieChart,
    DoughnutChart,
    Map,
    BarChart,
    BarChart3,
    AutocompleteSelect,
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
  },
  data() {
    return {
      currencyCode: "USD",
      alert: false,
      //summaryData: null,
      items: [1, 2, 3, 4],
      nProcesses: 0,
      data: {
        labels: ["A", "B", "C"],
        series: [
          [1, 3, 2],
          [4, 6, 5],
        ],
      },
      options: {
        lineSmooth: false,
      },
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartDataProdutos: {},
      chartDataCanais: {},
      datalogs: [],
      dataCollectionBarChart: {
        datasets: [
          {
            label: "Energia kW",
            fill: false,
            data: [1, 2, 3],
          },
        ],
      },
      encomendantes: orderers,
      optionsEncomendantes: [],
      optionsShipped: [
        { value: 0, text: "TODOS" },
        { value: 2, text: "A EMBARCAR" },
        { value: 1, text: "EM TRÂNSITO" },
        { value: 3, text: "CHEGOU" },
        { value: 4, text: "CANCELADOS" },
      ],
      //
      //exportadores: exporters,
      //optionsExportadores: exporters.map((el) => el.substring(0, 20)),
      optionsExportadores: [],
      //chipsExportadores: [],
      sortBy: "PRI_DTA_ABERTURA",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      search: "",
      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      processesHeaders: [
        {
          text: "Detalhes",

          align: "start",
          sortable: false,
          value: "id",
          width: "20px",
        },
        {
          text: "Referência interna",
          value: "PRI_ESP_REFCLIENTE",
          width: "25px",
          sortable: false,
        },
        {
          text: "Referência do cliente",
          value: "INV_ESP_PO",
          width: "20px",
          sortable: false,
        },

        { text: "Status", value: "PRI_VLD_STATUS", width: "100px" },
        { text: "Canal", value: "CANAL", width: "100px" },
        { text: "Data de abertura", value: "PRI_DTA_ABERTURA" },
        { text: "Local de desembaraço", value: "ARMAZEM_BL", width: "400px" },
        { text: "Incoterm", value: "INC_ESP_SIGLA" },
        { text: "DI", value: "DI", width: "130px" },
        { text: "Registro DI", value: "REGISTRO_DI" },

        {
          text: "Previsão embarque",
          value: "DTA_PREV_EMBARQUE_BL",
          sortable: false,
        },
        { text: "Embarque", value: "DTA_EMBARQUE_BL", sortable: false },
        {
          text: "Previsão chegada",
          value: "DTA_PREV_CHEGADA_BL",
          sortable: false,
        },
        { text: "Chegada", value: "DTA_CHEGADA_BL", sortable: false },
        //{ text: "Origem", value: "PAÍS ORIGEM" },
      ],
      loading: false,
      menu: false,
      menu2: false,
      labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
      time: 0,
      forecast: [
        {
          day: "Tuesday",
          icon: "mdi-white-balance-sunny",
          temp: "24\xB0/12\xB0",
        },
        {
          day: "Wednesday",
          icon: "mdi-white-balance-sunny",
          temp: "22\xB0/14\xB0",
        },
        { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
      ],
      amount: 0,
      amountFob: 0,
      countProcesses: 0,

      colors: ["blue", "orange", "pink", "green", "purple"],
      startDate: "",
      endDate: "",
      canaisAcc: [],
      embarqueAcc: [],
      exportersAcc: [],
      chartDataExportadores: {},
      chartDataEmbarque: {},
      armazemBlAcc: [],
      chartDataArmazen: [],
      produtosAcc: [],
      vlrDolarFinalChartData: {},
      monthFreteChartData: {},
      armadorAcc: [],
      myExporters: [],
      originCountries: [],
      countries,
      mounting: true,
      countryLayers: [],
      rawCountriesAcc: [],
      siglas: [],
    };
  },
  async mounted() {
    if (this.user?.role.id == 9) {
      this.setProcesses(this.user.processes);
      this.mounting = false;
      return;
      //await this.getProcesses();
      //return;
    }

    this.encomendantes = await strapiApi.enterprises.getOptions();

    const adminView =
      this.user?.role?.id == 1 ||
      this.user?.role?.id == 6 ||
      this.user?.role?.id == 10;

    if (!adminView) {
      this.optionsEncomendantes = this.user?.enterprises?.map(
        (el) => el && el.dpeNomPessoa?.substring(0, 20)
      );
      if (this.user?.email == "demo@stmarie.com.br") {
        this.optionsEncomendantes = this.optionsEncomendantes.map((el) =>
          el?.includes("ARTEX") ? "DEMO" : el
        );
      }

      if (this.optionsEncomendantes?.length == 1) {
        this.chipsEncomendantes = this.user?.enterprises.map(
          (el) => el && el.dpeNomPessoa?.substring(0, 20)
        );
      }
      await this.updateExporters();
      //Update processes headers
    } else {
      this.optionsEncomendantes = this.encomendantes.map(
        (el) => el && el.substring(0, 20)
      );

      await this.updateExporters();

      const indexToInsertAfter = this.processesHeaders.findIndex(
        (obj) => obj.value === "PRI_DTA_ABERTURA"
      );

      //

      // If the index is found, insert the object after that index
      if (indexToInsertAfter !== -1) {
        this.processesHeaders.splice(indexToInsertAfter + 1, 0, {
          text: "Encomendante",
          value: "DPE_NOM_PESSOA_LIG",
          width: "420px",
        });
      }
    }
    if (this.processes.length > 0) {
      this.updateGui();
    } else {
      this.mounting = false;
    }
  },

  watch: {
    dateFilterStart(val, oldVal) {
      if (val === oldVal) return;
      this.updateExporters();
    },
    dateFilterStart(val, oldVal) {
      if (val === oldVal) return;
      this.updateExporters();
    },
  },

  computed: {
    ...mapState([
      "invoice",
      "processes",
      "invoices_items",
      "transports",
      "containers",
      "countriesAcc",
      "summaryData",
      "kpis",
    ]),
    ...mapState({
      user: (state) => state.auth.user,
    }),

    simpleProcessesView() {
      return this.user?.role?.id == 9;
    },

    modeAdmin() {
      return (
        this.user &&
        this.user.role &&
        this.user.role.id &&
        (this.user.role.id == 6 || this.user.role.id == 1)
      );
    },

    chipsEncomendantes: {
      get() {
        return this.$store.state.chipsEncomendantes;
      },
      async set(value) {
        this.setChipsEncomendantes(value);

        this.updateExporters();
      },
    },
    serverItemsLength: {
      get() {
        return this.summaryData?.totals && this.summaryData?.totals[0]?.count;
      },
    },

    chipsExportadores: {
      get() {
        return this.$store.state.chipsExportadores;
      },
      async set(value) {
        this.setChipsExportadores(value);
      },
    },

    filterShipped: {
      get() {
        return this.$store.state.filterShipped;
      },
      async set(value) {
        this.setFilterShipped(value);
      },
    },

    dateFilterStart: {
      get() {
        return this.$store.state.dateFilterStart;
      },
      set(value) {
        this.setDateFilterStart(value);
      },
    },
    dateFilterEnd: {
      get() {
        return this.$store.state.dateFilterEnd;
      },
      set(value) {
        this.setDateFilterEnd(value);
      },
    },
  },

  methods: {
    ...mapMutations({
      setInvoices: "SET_INVOICES",
      setProcesses: "SET_PROCESSES",
      setInvoicesItems: "SET_INVOICES_ITEMS",
      setChipsEncomendantes: "SET_CHIPS_ENCOMENDANTES",
      setDateFilterStart: "SET_DATE_FILTER_START",
      setDateFilterEnd: "SET_DATE_FILTER_END",
      setTransports: "SET_TRANSPORTS",
      setContainers: "SET_CONTAINERS",
      setCountriesAcc: "SET_COUNTRIES_ACC",
      setChipsExportadores: "SET_CHIPS_EXPORTADORES",
      setFilterShipped: "SET_FILTER_SHIPPED",
      setSummaryData: "SET_SUMMARY_DATA",
      setKpis: "SET_KPIS",
    }),

    getTextProcessStatus(statusId, process) {
      if (statusId == 1 && process?.DTA_FECH_OPERACIONAL) {
        return "FECHADO";
      }
      switch (statusId) {
        case 1:
          return "ABERTO";
        case 2:
          return "CONCLUÍDO";
        case 3:
          return "ARQUIVADO";
        case 9:
          return "CANCELADO";
      }
    },

    getStyleProcessStatus(statusId, process) {
      if (statusId == 1 && process?.DTA_FECH_OPERACIONAL) {
        return "color:gray;";
      }
      switch (statusId) {
        case 1:
          return "color:black;";
        case 2:
          return "color:green;";
        case 3:
          return "color:gray;";
        case 9:
          return "color:red";
      }
    },

    async exportToExcel() {
      let data = this.processes.map((el) => {
        let row = {};
        for (var i = 0; i < this.processesHeaders.length; i++) {
          let itemHeader = this.processesHeaders[i];
          if (itemHeader.text && itemHeader.value)
            if (itemHeader.value !== "id")
              row[itemHeader.text] = el[itemHeader.value];
        }

        return row;
      });

      const ws = utils.json_to_sheet(data);

      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Processos");

      writeFile(wb, "Processos.xlsx");
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      var dateStart = new Date(`${this.dateFilterStart}T03:00:00.000Z`);
      var dateEnd = new Date(`${this.dateFilterEnd}T03:00:00.000Z`);

      if (this.optionsEncomendantes.length == 0) {
        alert("Solicitar acesso ao TI");
        this.loading = false;

        return;
      }

      let enterprises = this.chipsEncomendantes;
      if (
        this.chipsEncomendantes.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        enterprises = this.optionsEncomendantes;
      }

      //AJUSTE Ricardo 30/06/23
      if (this.user?.email == "demo@stmarie.com.br") {
        enterprises = enterprises.map((el) =>
          el?.includes("DEMO") ? "ARTEX" : el
        );
      }

      let exporters = this.chipsExportadores;

      let user = this.user;
      let businessUnits = user.businessUnits;

      let { values, pagination } = await strapiApi.processes.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.search,
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped,
        businessUnits?.map((el) => el.ungCod)
      );

      if (this.user?.email == "demo@stmarie.com.br") {
        values = values.map((el) => ({
          ...el,
          DPE_NOM_PESSOA_LIG: el?.DPE_NOM_PESSOA_LIG?.includes("ARTEX")
            ? "DEMO"
            : el.DPE_NOM_PESSOA_LIG,
        }));
      }

      let processes = values;
      let transports = [];
      let invoices = [];
      let containers = [];
      let invoiceItems = [];

      for (var i = 0; i < processes.length; i++) {
        var process = processes[i];
        processes[i].STATUS_EMBARQUE = processes[i].DTA_EMBARQUE_BL
          ? "EM TRÂNSITO"
          : "A EMBARCAR";

        if (processes[i].DTA_CHEGADA_BL)
          processes[i].STATUS_EMBARQUE = "CHEGOU";

        if (processes[i].PRI_VLD_STATUS == 9)
          processes[i].STATUS_EMBARQUE = "CANCELADO";

        if (
          processes[i] &&
          processes[i].invoices &&
          processes[i].invoices.length > 0
        ) {
          processes[i].INC_ESP_SIGLA = processes[i].invoices[0].INC_ESP_SIGLA;
          processes[i].INV_ESP_PO = processes[i].invoices[0].INV_ESP_PO;
        }
        processes[i].QTY_TRANSPORTS =
          process.transports && process.transports.length;

        var transportsFromProcesses = process.transports;
        if (transportsFromProcesses && transportsFromProcesses.length > 0) {
          processes[i].CNT_DTA_PREV_EMB =
            transportsFromProcesses[0].CNT_DTA_PREV_EMB;
          processes[i].CNT_DTA_EMBARQUE =
            transportsFromProcesses[0].CNT_DTA_EMBARQUE;
          processes[i].CNT_DTA_CHEGADA =
            transportsFromProcesses[0].CNT_DTA_CHEGADA;

          for (var b = 0; b < transportsFromProcesses.length; b++) {
            var transport = transportsFromProcesses[b];
            var containersFromProcesses = transport.containers;
            for (var c = 0; c < containersFromProcesses.length; c++) {
              containersFromProcesses[c].ARMADOR = transport.ARMADOR;
            }
            if (containersFromProcesses && containersFromProcesses.length > 0)
              containers = containers.concat(containersFromProcesses);
          }
        }

        transports = transports.concat(transportsFromProcesses);

        //invoices
        var invoicesFromProcesses = process.invoices;
        if (invoicesFromProcesses && invoicesFromProcesses.length > 0)
          for (var b = 0; b < invoicesFromProcesses.length; b++) {
            var invoice = invoicesFromProcesses[b];
            var itemsFromProcesses = invoice.items;

            if (itemsFromProcesses && itemsFromProcesses.length > 0)
              invoiceItems = invoiceItems.concat(itemsFromProcesses);
          }
        invoices = invoices.concat(invoicesFromProcesses);
      }

      this.setInvoices(invoices);

      this.setInvoicesItems(invoiceItems);

      this.setTransports(transports);

      this.setContainers(containers);

      this.setProcesses(processes);

      this.setProcesses(values);
    },

    async updateExporters() {
      let filterEncomendantes;

      filterEncomendantes = this.chipsEncomendantes;
      if (
        this.chipsEncomendantes.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        filterEncomendantes = this.optionsEncomendantes;
      }

      var myExporters = await strapiApi.processes.getExporters(
        new Date(`${this.dateFilterStart}T03:00:00.000Z`),
        new Date(`${this.dateFilterEnd}T03:00:00.000Z`),
        filterEncomendantes
      );
      this.optionsExportadores = myExporters.map((el) => el.substring(0, 20));

      for (var a = 0; a < this.chipsExportadores.length; a++) {
        var check =
          this.optionsExportadores &&
          this.optionsExportadores.find(
            (el) => el == this.chipsExportadores[a]
          );
        if (!check) {
          this.chipsExportadores.splice(
            this.chipsExportadores.indexOf(this.chipsExportadores[a]),
            1
          );
        }
      }
      // }else{

      // }
    },

    updateChipsEncomendantes(val) {
      this.chipsEncomendantes = val;
    },

    removeChip(item) {
      var chipsEncomendantes = JSON.parse(
        JSON.stringify(this.chipsEncomendantes)
      );

      chipsEncomendantes.splice(chipsEncomendantes.indexOf(item), 1);
      this.updateExporters();
      this.setChipsEncomendantes(chipsEncomendantes);
    },
    updateChipsExportadores(val) {
      this.chipsExportadores = val;
    },

    removeChipExportador(item) {
      var chipsExportadores = JSON.parse(
        JSON.stringify(this.chipsExportadores)
      );
      chipsExportadores.splice(chipsExportadores.indexOf(item), 1);
      this.chipsExportadores = chipsExportadores;
    },
    formatUsd(value, maximumFractionDigits, minimumFractionDigits) {
      var d = Math.round(value * 100) / 100;
      if (maximumFractionDigits < 0) maximumFractionDigits = 0;
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits:
          maximumFractionDigits !== null ? maximumFractionDigits : 2,
        minimumFractionDigits:
          maximumFractionDigits !== null ? maximumFractionDigits : 2,
      }).format(d || 0);
    },

    formatLabelChartUsd(value) {
      if (value >= 10 ** 9) {
        return `$ ${this.formatUsd(
          value / 10 ** 9,
          11 - Math.floor(Math.log(value) / Math.log(10))
        )} b`;
      } else {
        if (value >= 10 ** 6) {
          return `$ ${this.formatUsd(
            value / 10 ** 6,
            8 - Math.floor(Math.log(value) / Math.log(10))
          )} M`;
        } else {
          return `$ ${this.formatUsd(
            value / 10 ** 3,
            5 - Math.floor(Math.log(value) / Math.log(10))
          )} K`;
        }
      }
    },

    goToPage(priCode) {
      var process = this.processes.find((el) => el.PRI_COD == priCode);
      this.$router.push(`process/${priCode}?filCod=${process.FIL_COD}`);
    },

    activateAlert() {
      this.alert = true;
    },

    async getProcesses() {
      this.alert = false;
      this.loading = true;
      this.page = 1;
      var dateStart = new Date(`${this.dateFilterStart}T03:00:00.000Z`);
      var dateEnd = new Date(`${this.dateFilterEnd}T03:00:00.000Z`);

      this.setInvoices([]);

      this.setInvoicesItems([]);

      var invoice = [];
      var containers = [];
      var transports = [];
      var invoices = [];
      var invoiceItems = [];

      let enterprises = this.chipsEncomendantes;
      if (
        this.chipsEncomendantes.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        enterprises = this.optionsEncomendantes;
      }

      let exporters = this.chipsExportadores;

      //AJUSTE Ricardo 30/06/23
      if (this.user?.email == "demo@stmarie.com.br") {
        enterprises = enterprises.map((el) =>
          el?.includes("DEMO") ? "ARTEX" : el
        );
      }

      //Get Summary
      let summaryData = await strapiApi.processes.getSummary(
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped,
        this.user?.businessUnits?.map((el) => el.ungCod)
      );

      let moedas = summaryData.moedas;

      let moeEspSigla = "USD";

      let siglas = moedas
        ?.filter((el) => el.moe_esp_sigla)
        .map((el) => el.moe_esp_sigla);

      siglas = this.getUniqueFields(siglas);
      if (siglas?.length == 1) {
        this.currencyCode = siglas[0];
      } else {
        this.currencyCode = "USD";
      }
      this.siglas = siglas;
      // this.summaryData = summaryData;
      this.setSummaryData(summaryData);

      //get kpis
      let kpis = await strapiApi.processes.getKpis(
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped
      );
      this.setKpis(kpis);

      //Map
      let countriesAcc = this.formatAcc(summaryData?.countriesAcc, null, true);

      this.rawCountriesAcc = this.formatAcc(
        summaryData?.countriesAcc,
        null,
        true
      );

      countriesAcc = countriesAcc.map((el) => {
        let country = countries.find(
          (itemCountry) =>
            itemCountry && this.formatCountryName(itemCountry.value) == el.name
        );
        if (country)
          return {
            code: country.code,
            ...el,
          };
      });

      countriesAcc = countriesAcc.filter((el) => el);

      this.setCountriesAcc(countriesAcc);

      await this.doSearch();

      this.updateGui();
    },

    formatCountryName(string) {
      const arrayLetters = {
        a: "A",
        á: "A",
        à: "A",
        â: "A",
        ã: "A",
        ä: "A",
        e: "E",
        é: "E",
        è: "E",
        ê: "E",
        ë: "E",
        i: "I",
        í: "I",
        ì: "I",
        î: "I",
        ï: "I",
        o: "O",
        ó: "O",
        ò: "O",
        ô: "O",
        õ: "O",
        ö: "O",
        u: "U",
        ú: "U",
        ù: "U",
        û: "U",
        ü: "U",
        ç: "C",
        Ç: "C",
      };

      return string
        .split("")
        .map((letter) => arrayLetters[letter] || letter)
        .join("")
        .toUpperCase();
    },

    getMax(items) {
      return items && items.reduce((acc, value) => acc + value.total, 0);
    },

    getUniqueFields(arr) {
      return [...new Set(arr)];
    },

    updateGui() {
      // this.armadorAcc = this.getInfoGrouped(
      //   this.containers,
      //   "ARMADOR",
      //   "COUNT",
      //   4,
      //   true
      // );
      //Moedas

      if (this.summaryData) {
        let moedas = this.summaryData.moedas;

        let siglas = moedas
          ?.filter((el) => el.moe_esp_sigla)
          .map((el) => el.moe_esp_sigla);

        siglas = this.getUniqueFields(siglas);
        if (siglas?.length == 1) {
          this.currencyCode = siglas[0];
        } else {
          this.currencyCode = "USD";
        }
      }

      if (this.summaryData)
        this.armadorAcc = this.formatAcc(this.summaryData?.armadorAcc, 4, true);

      //this.formatAcc(summaryData?.countriesAcc, null, true);

      // this.amount = this.processes.reduce(
      //   (acc, val) => acc + (val.VLR_TOT_INV ? val.VLR_TOT_INV : 0),
      //   0
      // );

      // this.amountFob = this.processes.reduce(
      //   (acc, val) => acc + (val.VLR_TOT_INV ? val.VLR_TOT_INV : 0),
      //   0
      // );

      if (this.summaryData) {
        this.amount =
          this.summaryData?.totals && this.summaryData?.totals[0].sum_fob;
        this.amountFob =
          this.summaryData?.totals && this.summaryData?.totals[0].sum_fob;

        this.countProcesses =
          this.summaryData?.totals && this.summaryData?.totals[0].count;
      }

      //Produtos
      // var produtosAcc = this.getInfoGrouped(
      //   this.invoices_items,
      //   "PRD_DES_NOME",
      //   "VLR_FINAL",
      //   5
      // );
      //this.chartDataProdutos = this.getDoughnutChartData(produtosAcc);

      let produtosAcc;
      if (this.summaryData)
        produtosAcc = this.formatAcc(this.summaryData?.productsAcc, 5);

      this.chartDataProdutos = this.getDoughnutChartData(produtosAcc);

      this.produtosAcc = produtosAcc;

      let canaisAcc;
      if (this.summaryData)
        canaisAcc = this.formatAcc(this.summaryData?.canaisAcc);

      let exportersAcc;
      if (this.summaryData)
        exportersAcc = this.formatAcc(this.summaryData?.exportersAcc, 5);

      let embarqueAcc;
      if (this.summaryData)
        embarqueAcc = this.formatAcc(this.summaryData?.embarcadoAcc);

      let armazemBlAcc;
      if (this.summaryData)
        armazemBlAcc = this.formatAcc(this.summaryData?.armazemBlAcc, 5, true);

      this.armazemBlAcc = armazemBlAcc;

      this.chartDataEmbarque = this.getDoughnutChartDataShipped(embarqueAcc);

      this.embarqueAcc = embarqueAcc;

      this.exportersAcc = exportersAcc;

      this.chartDataCanais = this.getDoughnutChartDataCanais(canaisAcc);

      this.canaisAcc = canaisAcc;

      this.chartDataExportadores = this.getDoughnutChartData(exportersAcc);

      //sort on frontend
      this.summaryData?.dateAcc?.sort(
        (a, b) =>
          (a.year_month && new Date(`${a.year_month}-01T03:00:00.000z`)) -
          (b.year_month && new Date(`${b.year_month}-01T03:00:00.000z`))
      );

      this.vlrDolarFinalChartData = this.getMonthChartData(
        this.summaryData?.dateAcc,
        "total_fob",
        [
          this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
          "#08b4ec",
          "#007AA3",
          "#00A1A9",
          "#00C49A",
          "#91E380",
          "#F9F871",
          "#415E99",
          "#6E67A9",
          "#996FB4",
          "#C276B8",
          "#E87FB7",
          "#4E84B9",
        ],
        -20,
        "#FFF",
        true
      );
      this.monthFreteChartData = this.getMonthChartData(
        this.summaryData?.dateAcc,
        "total_frete",
        [
          "#fecb4c",
          "#ff8a00",
          "yellow",
          "#A0BF53",
          "#4FA86D",
          "#008A7D",
          "#0E6976",
          "#2F4858",
          "#B29866",
          "#FFEECA",
        ]
      );

      this.$forceUpdate();
      this.loading = false;
      this.mounting = false;
    },
    chartClicked(e) {
      const chart = this.$refs.mychart;
      console.log(
        chart.getElementsAtEventForMode(
          e,
          "nearest",
          { intersect: true },
          false
        )
      );
    },

    getMonthChartData(
      items,
      fieldName,
      colors,
      offset,
      labelColor,
      showMillions
    ) {
      //Datalogs
      if (!items || items.length == 0) {
        return { dataCollection: { datasets: [] }, options: {} };
      }
      var endDate = new Date(
        `${items[items.length - 1].year_month}-01T03:00:00.000z`
      );

      //endDate.setMonth(endDate.getMonth() + 1);

      endDate.setMonth(12);

      this.endDate = endDate;

      var startDate = new Date(`${items[0].year_month}-01T03:00:00.000z`);
      startDate.setMonth(0);
      startDate.setDate(1);
      this.startDate = startDate;

      var startChartDate = new Date(this.startDate.getTime());
      startChartDate.setDate(1);

      var today = new Date();

      var months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];

      var dataCollection = {
        labels: [],
        datasets: [],
      };

      var chartDate = new Date(this.startDate.getTime());

      var year0 = startDate.getFullYear();
      var year = startDate.getFullYear();
      var a = 0;
      dataCollection.datasets.push({
        lineTension: 0,
        label: chartDate.getFullYear(),
        fontColor: colors && colors[a] ? colors[a] : "#08b4ec",
        backgroundColor: colors && colors[a] ? colors[a] : "#08b4ec",
        borderColor: colors && colors[a] ? colors[a] : "#08b4ec",
        pointBackgroundColor: colors && colors[a] ? colors[a] : "#08b4ec",
        fill: false,
        data: [],
      });

      while (chartDate < endDate) {
        if (chartDate.getFullYear() !== year) {
          year = chartDate.getFullYear();
          a++;
          dataCollection.datasets.push({
            lineTension: 0,
            label: chartDate.getFullYear(),
            fontColor: colors && colors[a] ? colors[a] : "#08b4ec",
            backgroundColor: colors && colors[a] ? colors[a] : "#08b4ec",
            borderColor: colors && colors[a] ? colors[a] : "#08b4ec",
            pointBackgroundColor: colors && colors[a] ? colors[a] : "#08b4ec",
            fill: false,
            data: [],
          });
        }

        if (year0 == year)
          dataCollection.labels.push(months[chartDate.getMonth()]);

        var nextMonth = new Date(chartDate.getTime());
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        let amountItem = items.find(
          (el) => el.year_month == chartDate?.toISOString()?.substring(0, 7)
        );
        var amount = amountItem && amountItem[fieldName];
        //This year
        dataCollection.datasets[a].data.push(Math.round(amount * 100) / 100);

        //Totalize data
        chartDate.setMonth(chartDate.getMonth() + 1);
      }

      let length = dataCollection?.datasets?.length;

      var options = {
        responsive: true,
        maintainAspectRatio: false,
        color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        scales: {
          x: {
            ticks: {
              color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
            },
          },
          y: {
            ticks: {
              color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if (value && length <= 1) {
                // if (value > 10 ** 6) {
                //   return `$ ${this.formatUsd(value / 10 ** 6, 2)} M`;
                // } else {
                //   return `$ ${this.formatUsd(value, 0)}`;
                // }
                return this.formatLabelChartUsd(value);
              } else return "";
            },
            anchor: "end",
            align: "top",
            clamp: true,
            color: labelColor ? labelColor : "#000",
            offset: offset ? offset : 5, // offset: -20,
            padding: {
              bottom: 0,
              top: 0,
            },
            font: {
              family: "Poppins",
              size: 10,
              weight: "bold",
            },
          },

          legend: {
            position: "top",
            fontColor: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,

            color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
          },
        },
      };

      return { dataCollection, options };
    },

    getDoughnutChartDataCanais(canaisAcc) {
      var labels = [];
      var colors = [
        this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        "#00b5e9",
        "#00d3db",
        "#fecb4c",
      ];
      var definitions = [
        { text: "VERDE", color: "green" },
        { text: "AMARELO", color: "#FFD700" },
        { text: "VERMELHO", color: "red" },
        {
          text: "                                                                                                                                                                                                                         ",
          color: "transparent",
        },
        {
          text: "                                                                                                                                                                                                                         ",
          color: "transparent",
        },
        {
          text: "                                                                                                                                                                                                                         ",
          color: "transparent",
        },
      ];

      var data = [];
      var backgroundColor = [];

      for (var c = 0; c < definitions.length; c++) {
        var canalAcc = canaisAcc.find((el) => el.name == definitions[c].text);
        definitions[c].value = canalAcc ? canalAcc.total : 0;
        labels.push(
          definitions[c].text +
            "                                                                                                                                    "
        );
        data.push(definitions[c].value);
        backgroundColor.push(definitions[c].color);
      }

      var chartData = {
        labels,
        datasets: [
          {
            backgroundColor,
            data,
          },
        ],
      };
      return chartData;
    },

    getDoughnutChartDataShipped(shippedAcc) {
      var labels = [];
      var colors = [
        this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        "#00b5e9",
        "#00d3db",
        "#fecb4c",
        "blue",
        "lightblue",
        "",
      ];

      var definitions = [
        {
          text: "A EMBARCAR",
          color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        },
        { text: "EM TRÂNSITO", color: "#00b5e9" },
        { text: "CHEGOU", color: "#007F00" },
        { text: "CANCELADO", color: "red" },
      ];

      var data = [];
      var backgroundColor = [];

      for (var c = 0; c < definitions.length; c++) {
        var item = shippedAcc.find((el) => el.name == definitions[c].text);
        definitions[c].value = item ? item.total : 0;
        labels.push(
          definitions[c].text +
            "                                                                                                                                    "
        );
        data.push(definitions[c].value);
        backgroundColor.push(definitions[c].color);
      }

      var chartData = {
        labels,
        datasets: [
          {
            backgroundColor,
            data,
          },
        ],
      };
      return chartData;
    },

    getDoughnutChartData(values, maxItens) {
      if (!maxItens) maxItens = 6;
      var labels = [];
      var colors = [
        this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        "#00b5e9",
        "#00d3db",
        "#fecb4c",
        "blue",
        "lightblue",
        "",
      ];
      var data = [];

      //Acc 4 options
      var valuesSummary = JSON.parse(JSON.stringify(values));
      var a = 0;

      while (valuesSummary.length < maxItens) {
        valuesSummary.push({
          name: "                                                                                                                                                          ",
          total: 0,
        });
      }

      var backgroundColor = [];
      for (var c = 0; c < valuesSummary.length; c++) {
        if (valuesSummary[c].name !== "null") {
          labels.push(
            valuesSummary[c].name +
              "                                                                                                                                                                                                                                    "
          );
          data.push(valuesSummary[c].total);
          if (c < colors.length) backgroundColor.push(colors[c]);
        }
      }
      var chartData = {
        labels,
        datasets: [
          {
            backgroundColor,
            data,
          },
        ],
      };
      return chartData;
    },

    formatAcc(values, maxItens, hideNotShipped) {
      if (!values) return [];

      if (hideNotShipped) {
        values = values.filter((el) => el.name !== "NÃO EMBARCADO");
      }
      values = values.filter((el) => el.name !== null);
      values.sort((a, b) => b.total - a.total);
      var valuesSummary = [];

      if (maxItens) {
        var a = 0;
        for (a = 0; a < maxItens; a++) {
          if (a < values.length && values[a]) valuesSummary.push(values[a]);
        }
        if (values.length >= maxItens) {
          var totalOthers = 0;
          for (a = maxItens; a < values.length; a++) {
            totalOthers += values[a].total;
          }
          if (totalOthers)
            valuesSummary.push({ name: "OUTROS", total: totalOthers });
        }
      }

      return maxItens ? valuesSummary : values;
    },
  },
};
</script>

<style>
.border {
  border-style: dashed;
}

.gray {
  color: gray;
}

.green {
  color: green;
}

.red {
  color: red;
}

.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}

.dashboard-width {
  max-width: 2400px;
}

table {
  font-family: "Poppins";
}
td {
  border-right: 1px solid #dddddd;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins";
}

th {
  border-right: 1px solid #dddddd;
  font-size: 16px !important;
  font-weight: 500 !important;
  vertical-align: top;
  padding: 10px !important;
}

.subtitle {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.text-highlight {
  color: #37a7b6;
  font-size: 32px !important;
  font-weight: 800 !important;
}
/* p {
  font-weight: 500 !important;
} */

/* span {
  font-weight: 500 !important;
} */
h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.frozen-columns {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff; /* Adjust background color if needed */
}

.dashboard-background {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}

.header-frozen {
  background-color: white;
  margin: 10px;
  border-bottom: 0.1px solid #dddddd;
  border-left: 0.1px solid #dddddd;
  position: absolute;

  z-index: 2;
  border-width: 0.01px;
  border-color: #dddddd;

  height: 93px;
  width: 91px;
  margin-top: -34px;
  margin-left: -11px;
  padding: 0px;
  padding-top: 10px;
  padding-left: 6px;
}
</style>
