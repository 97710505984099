var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-container',{attrs:{"fluid":""}},[_c('br'),_c('h1',[_vm._v("Fechamento de processos")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.tabs),function(tab,index){return _c('v-col',{key:index},[_c('CardValue',{attrs:{"title":tab.title,"color":tab.color,"value":tab.summary}})],1)}),1)],1)],1),_c('v-row',[_c('v-select',{staticClass:"mx-5",staticStyle:{"color":"black","font-size":"16px !important","font-weight":"600 !important"},attrs:{"label":"Filial","items":[
            { value: 0, text: 'Todas' },
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 6, text: 6 },
            { value: 7, text: 7 },
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 12, text: 12 },
            { value: 13, text: 13 },
            { value: 14, text: 14 },
            { value: 16, text: 16 },
            { value: 17, text: 17 },
            { value: 18, text: 18 },
            { value: 20, text: 20 },
            { value: 21, text: 21 },
          ],"item-text":"text","item-value":"value"},model:{value:(_vm.filters.FIL_COD),callback:function ($$v) {_vm.$set(_vm.filters, "FIL_COD", $$v)},expression:"filters.FIL_COD"}}),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Referência do processo"},model:{value:(_vm.filters.PRI_ESP_REFCLIENTE),callback:function ($$v) {_vm.$set(_vm.filters, "PRI_ESP_REFCLIENTE", $$v)},expression:"filters.PRI_ESP_REFCLIENTE"}}),_c('DateInput',{attrs:{"label":"Período de"},model:{value:(_vm.filters.dateStart),callback:function ($$v) {_vm.$set(_vm.filters, "dateStart", $$v)},expression:"filters.dateStart"}}),_c('DateInput',{attrs:{"label":"Período Até"},model:{value:(_vm.filters.dateEnd),callback:function ($$v) {_vm.$set(_vm.filters, "dateEnd", $$v)},expression:"filters.dateEnd"}}),_c('v-select',{staticClass:"mx-5",staticStyle:{"color":"black","font-size":"16px !important","font-weight":"600 !important"},attrs:{"label":"Status","items":[
            { value: 0, text: 'TODOS' },
            { value: 1, text: 'PENDENTES' },
            { value: 2, text: 'CAPTURADOS' },
            { value: 3, text: 'FINALIZADOS' },
            { value: 4, text: 'EMAIL PENDENTE' },
          ],"item-text":"text","item-value":"value"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('v-select',{staticClass:"mx-5",staticStyle:{"color":"black","font-size":"16px !important","font-weight":"600 !important"},attrs:{"label":"Câmbio aberto","items":[
            { value: 0, text: 'TODOS' },
            { value: 1, text: 'SIM' },
            { value: 2, text: 'NÃO' },
          ],"item-text":"text","item-value":"value"},model:{value:(_vm.filters.cambio),callback:function ($$v) {_vm.$set(_vm.filters, "cambio", $$v)},expression:"filters.cambio"}}),_c('v-select',{staticClass:"mx-5",staticStyle:{"color":"black","font-size":"16px !important","font-weight":"600 !important"},attrs:{"label":"Responsável","items":[
            { value: 0, text: 'TODOS' },
            ..._vm.users.map((el) => ({
              value: el.id,
              text: el.username,
            })),
          ],"item-text":"text","item-value":"value"},model:{value:(_vm.filters.userId),callback:function ($$v) {_vm.$set(_vm.filters, "userId", $$v)},expression:"filters.userId"}}),_c('v-select',{staticClass:"mx-5",staticStyle:{"color":"black","font-size":"16px !important","font-weight":"600 !important"},attrs:{"label":"Tem diferença?","items":[
            { value: 0, text: 'TODOS' },
            { value: 1, text: 'SIM' },
            { value: 2, text: 'NÃO' },
          ],"item-text":"text","item-value":"value"},model:{value:(_vm.filters.nota_debito),callback:function ($$v) {_vm.$set(_vm.filters, "nota_debito", $$v)},expression:"filters.nota_debito"}}),_c('div',{staticClass:"mt-4"},[(!(_vm.user && _vm.user.cmn025 && _vm.user.profileId == 3))?_c('AutocompleteSelect',{attrs:{"label":"Encomendante","items":_vm.optionsEncomendantes},model:{value:(_vm.chipsEncomendantes),callback:function ($$v) {_vm.chipsEncomendantes=$$v},expression:"chipsEncomendantes"}}):_vm._e()],1),_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.loading},on:{"click":_vm.refresh}},[_vm._v("Buscar ")])],1)],1),_c('br'),_c('v-tabs',{staticStyle:{"min-height":"600px"},attrs:{"color":"primary accent-4","active-class":"white black--text"}},[_vm._l((_vm.tabs.filter((el) => el.show)),function(tab,index){return _c('v-tab',{key:index,staticClass:"white--text",style:(`background-color:${tab.color}`)},[_c('b',[_vm._v(_vm._s(tab.title))])])}),_vm._l((_vm.tabs),function(tab,index){return _c('v-tab-item',{key:index},[_c('TabelaProcessosFechamento',{attrs:{"date-field":tab.dateField,"date-fields":_vm.tabs.map((el) => el.dateField),"dateCaptureField":tab.dateCaptureField,"fieldLabel":tab.fieldLabel,"user-field":tab.userField,"lastRefresh":tab.lastRefresh,"filters-parent":_vm.formatFilters(),"filter-status":_vm.filters.status,"title":tab.title,"user-id":_vm.filters.userId,"slug":tab.slug,"tabs":_vm.tabs,"tab":tab}})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }