<template>
  <v-container fluid>
    <!-- Dialog comments -->
    <DialogComments
      v-model="showDialogComments"
      :process="selectedProcess"
    ></DialogComments>

    <DialogFiles
      v-model="showDialogFiles"
      process-id="300016607"
      :process="selectedProcess"
    ></DialogFiles>

    <DialogCapture
      :item-selected="itemSelected"
      v-model="showDialog"
      @doCapture="doCapture"
    ></DialogCapture>

    <DialogMove
      :item-selected="itemSelected"
      v-model="showDialogMove"
      @doMove="doMove"
      :tabs="tabs"
    ></DialogMove>

    <v-dialog v-model="showDialogEmail" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogEmail = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline"
            >Processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}</span
          >
        </v-card-title>

        <v-card-text class=""> Confirma que email foi enviado </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doEmailSent(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogEmail = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogEdit" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogEdit = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Editar nota</span>
        </v-card-title>

        <v-card-text class="">
          <v-currency-field
            label="Valor"
            prefix="R$"
            class="mx-2"
            style="font-size: 14px"
            filled
            rounded
            dense
            solo
            v-model="itemSelected[`VL_NOTA_${tipoNota}`]"
          />
        </v-card-text>

        <v-card-text class="">
          Confirmar edição do processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="green dark"
            color="white"
            text
            @click="doEdit(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red dark"
            color="white"
            text
            @click="showDialogEdit = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- finish -->
    <v-dialog v-model="showDialogFinish" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogFinish = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Finalizar etapa</span>
        </v-card-title>
        <v-card-text class="">
          Confirmar fechamento da etapa do processo
          {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doFinish(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogFinish = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- refuse -->
    <v-dialog v-model="showDialogRefuse" width="600px">
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogRefuse = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Retornar à etapa inicial</span>
        </v-card-title>
        <v-card-text class="" style="">
          Confirmar retorno à etapa inicial?
          {{ itemSelected?.PRI_ESP_REFCLIENTE }}
        </v-card-text>

        <v-card-text>
          <v-textarea label="Observações" v-model="refuseText"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="green"
            color="white"
            text
            @click="doRefuse(itemSelected)"
          >
            Sim
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="red"
            color="white"
            text
            @click="showDialogRefuse = false"
          >
            Não
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- User history -->
    <DialogUserLogs
      v-model="showDialogUserLogs"
      :tabs="tabs"
      :itemSelected="itemSelected"
    ></DialogUserLogs>

    <!-- report -->
    <v-dialog v-model="showDialogReport" fluid>
      <v-card>
        <v-toolbar class="primary dark">
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="showDialogReport = false">
            Fechar
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span class="headline">Relatório de despesas</span>
        </v-card-title>

        <v-card-text>
          <TabelaProcessosTotais
            :process="itemSelected"
            :last-refresh="lastRefreshExpenses"
          ></TabelaProcessosTotais>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="ma-1">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="exportToExcel"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white"> mdi-download </v-icon></v-btn
          >
        </template>
        <span>Exportar para excel</span>
      </v-tooltip>
    </v-row>

    <br />
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="custom-table"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length.sync="serverItemsLength"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      @update:items-per-page="updateRowsPerPage"
      @update:page="updatePage"
      ref="dataTable"
    >
      <template v-slot:item.DTA_EMISS_1SAIDA="{ item }">
        <span v-date-format="item.DTA_EMISS_1SAIDA"></span>
      </template>
      <template v-slot:item.DTA_CAPTURA_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_CAPTURA_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_FECH_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_FECH_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_FIM_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_FIM_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_FIM_FATURAMENTO="{ item }">
        <span v-date-format="item.DTA_FIM_FATURAMENTO"></span>
      </template>

      <template v-slot:item.DTA_FIM_CONTABIL="{ item }">
        <span v-date-format="item.DTA_FIM_CONTABIL"></span>
      </template>

      <template v-slot:item.DTA_FIM_OPERACIONAL="{ item }">
        <span v-date-format="item.DTA_FIM_OPERACIONAL"></span>
      </template>

      <template v-slot:item.DTA_CAPTURA_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_CAPTURA_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_FECH_FINANCEIRO="{ item }">
        <span v-date-format="item.DTA_FECH_FINANCEIRO"></span>
      </template>

      <template v-slot:item.DTA_CAPTURA_CONTABIL="{ item }">
        <span v-date-format="item.DTA_CAPTURA_CONTABIL"></span>
      </template>

      <template v-slot:item.DTA_FECH_CONTABIL="{ item }">
        <span v-date-format="item.DTA_FECH_CONTABIL"></span>
      </template>

      <template v-slot:item.USER_CAPTURA_OPERACIONAL="{ item }">
        <span>{{ item.USER_CAPTURA_OPERACIONAL?.username }}</span>
      </template>

      <template v-slot:item.USER_CAPTURA_FINANCEIRO="{ item }">
        <span>{{ item.USER_CAPTURA_FINANCEIRO?.username }}</span>
      </template>
      <template v-slot:item.USER_CAPTURA_CONTABIL="{ item }">
        <span>{{ item.USER_CAPTURA_CONTABIL?.username }}</span>
      </template>

      <template v-slot:item.USER_CAPTURA_FINAL="{ item }">
        <span>{{ item.USER_CAPTURA_FINAL?.username }}</span>
      </template>

      <template v-slot:item.daysToEvent="{ item }">
        <span>{{ daysToEvent(item[tab?.dateField]) }}</span>
      </template>
      <template v-slot:item.CAMBIO_ABERTO="{ item }">
        <span>{{ item.CAMBIO_ABERTO ? "Sim" : "Não" }}</span>
      </template>
      <!-- <template v-slot:item.PRI_VLD_TIPO="{ item }">
        <span>{{ getTipoProcessoText(item.PRI_VLD_TIPO) }}</span>
      </template> -->

      <template v-slot:item.PRI_ESP_REFCLIENTE="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              @click="
                openLink(
                  ` https://saintemarie.conexos.cloud/arq020#/?telaOrigin=TelaProcesso&codeView=imp021&filCod=${item?.FIL_COD}&priCod=${item?.PRI_COD}`
                )
              "
            >
              {{ item.PRI_ESP_REFCLIENTE }}
            </button>
          </template>
          <span>Clique para acessar GED </span>
        </v-tooltip>
      </template>

      <!-- Action -->
      <template v-slot:item.action="{ item }">
        <div class="mx-2">
          <div style="display: flex">
            <!-- User logs -->
            <div style="background-color: grey" class="ma-1 pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogUserLogs(item)"
                    >mdi-clipboard-account-outline</v-icon
                  >
                </template>
                <span>Usuários responsáveis e logs</span>
              </v-tooltip>
            </div>

            <!-- Capture userField = userCapture -->
            <div
              v-if="!item[tab?.userField]?.username"
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialog(item)"
                    >mdi-select</v-icon
                  >
                </template>
                <span>Capturar </span>
              </v-tooltip>
            </div>

            <!-- Finish -->
            <div
              v-if="
                item[tab?.userField]?.username &&
                !(item[tab.dateClose] ? true : false)
              "
              style="background-color: green"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogFinish(item)"
                    >mdi-check</v-icon
                  >
                </template>
                <span>Finalizar</span>
              </v-tooltip>
            </div>

            <!-- Refuse -->
            <div
              v-if="
                item[tab?.userField]?.username &&
                !(item[tab.dateClose] ? true : false) &&
                (tab?.slug == 'contabil' || tab?.slug == 'faturamento')
              "
              style="background-color: red"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogRefuse(item)"
                    >mdi-alpha-x-circle-outline
                  </v-icon>
                </template>
                <span>Recusar</span>
              </v-tooltip>
            </div>

            <!-- Email Sent -->
            <div
              v-if="item.EMAIL_SENT && showEmailButton(item)"
              style="color: gray"
              class="ma-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on" color="green" @click=""
                    >mdi-email-check-outline</v-icon
                  >
                </template>
                <span>Email enviado</span>
              </v-tooltip>
            </div>

            <!-- Email Sent -->
            <div v-if="!item.EMAIL_SENT && showEmailButton(item)" class="ma-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    @click="openDialogEmail(item)"
                    >mdi-email-arrow-right-outline</v-icon
                  >
                </template>
                <span>Envio de email pendente</span>
              </v-tooltip>
            </div>

            <!-- Comments -->
            <div style="background-color: gray" class="ma-1 pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogComments(item)"
                    >mdi-comment-text-multiple-outline</v-icon
                  >
                </template>
                <span>Obs</span>
              </v-tooltip>
            </div>

            <!-- Files -->
            <div style="background-color: gray" class="ma-1 pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogFiles(item)"
                    >mdi-file-multiple</v-icon
                  >
                </template>
                <span>Files</span>
              </v-tooltip>
            </div>

            <!-- Move -->
            <div style="background-color: gray" class="ma-1 pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogMove(item)"
                    >mdi-swap-horizontal</v-icon
                  >
                </template>
                <span>Encaminhar processo</span>
              </v-tooltip>
            </div>

            <div
              style="background-color: green; padding: 4px; color: white"
              v-if="item[`${tab.dateClose}`]"
            >
              Finalizado
            </div>
          </div>
        </div>
      </template>

      <!-- Edit -->
      <template v-slot:item.nota="{ item }">
        <div>
          <div style="display: flex">
            <span
              v-if="item.TIPO_NOTA_FISCAL"
              class="mt-2"
              v-money-format="item.VL_NOTA_FISCAL"
            ></span>
            <span v-if="item.TIPO_NOTA_FISCAL" class="ml-2 mt-2">{{
              item.TIPO_NOTA_FISCAL
            }}</span>

            <span v-if="!item.TIPO_NOTA_FISCAL">SEM NOTA</span>
            <div
              v-if="tab?.slug == 'faturamento' || tab?.slug == 'contabil'"
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEdit(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <!-- Debito -->
      <template v-slot:item.nota_debito="{ item }">
        <div>
          <div style="display: flex">
            <span class="mt-2" v-money-format="item.VL_NOTA_DEBITO"></span>

            <div
              v-if="
                (tab?.slug == 'operacional' || tab?.slug == 'contabil') &&
                item[tab?.userField]?.username &&
                !item[`${tab.dateClose}`]
              "
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEditDebito(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.nota_complementar="{ item }">
        <div>
          <div style="display: flex">
            <span
              class="mt-2"
              v-money-format="item.VL_NOTA_COMPLEMENTAR"
            ></span>

            <div
              v-if="
                (tab?.slug == 'operacional' || tab?.slug == 'contabil') &&
                item[tab?.userField]?.username &&
                !item[`${tab.dateClose}`]
              "
              style="background-color: grey"
              class="ma-1 pa-1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    @click="openDialogEditComplementar(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar </span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>
      <!-- Report -->
      <template v-slot:item.expenses="{ item }">
        <div
          v-if="item[tab?.dateCaptureField] || tab?.slug == 'fechados'"
          style="display: flex"
        >
          <div style="background-color: grey" class="ma-1 pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                  color="white"
                  @click="openDialogReport(item)"
                  >mdi-file-document-outline</v-icon
                >
              </template>
              <span>Relatório de despesas</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import DateInput from "../../components/fields/DateInput";
let dateStart = "2024-01-01";
import VCurrencyField from "../../components/VCurrencyField.vue";
import TabelaProcessosTotais from "./TabelaProcessosTotais.vue";
import DialogComments from "./DialogComments.vue";
import DialogFiles from "./DialogFiles.vue";
import DialogCapture from "./DialogCapture.vue";
import DialogUserLogs from "./DialogUserLogs.vue";
import DialogMove from "./DialogMove.vue";
import { read, utils, writeFile } from "xlsx";
import dateFormat from "../../../../libraries/utils/date-format";
import moneyFormat from "../../../../libraries/utils/money-format";

export default {
  name: "TabelaProcessosFechamentoR1",
  components: {
    DateInput,
    DialogFiles,
    VCurrencyField,
    TabelaProcessosTotais,
    DialogComments,
    DialogCapture,
    DialogUserLogs,
    DialogMove,
  },

  props: [
    "enterprise",
    "filtersParent",
    "sort",
    "sortBy",
    "sortDesc",
    "lastRefresh",
    "filterStatus",
    "filterUserId",
    "tab",
    "tabs",
  ],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    lastRefresh(val) {
      this.doSearch();
    },
    showDialog(val) {
      this.message = "";
    },
    showDialogEdit(val) {
      this.message = "";
    },
  },
  data() {
    return {
      menu: false,
      showDialogFiles: false,
      showDialogComments: false,
      selectedProcess: null,
      showDialog: false,
      showDialogEdit: false,
      showDialogReport: false,
      showDialogFinish: false,
      showDialogRefuse: false,
      showDialogEmail: false,
      showDialogUserLogs: false,
      showDialogMove: false,
      refuseText: "",
      expenses: [],
      itemSelected: {},
      lastRefreshExpenses: 0,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      items: [],
      message: "",
      tipoNota: "DEBITO", //"COMPLEMENTAR"
      filtersActive: {},
      headers: [
        {
          text: "Filial",
          align: "start",
          sortable: false,
          value: "FIL_COD",
        },

        {
          text: "Ref. Externa",
          align: "start",
          sortable: false,
          value: "PRI_ESP_REFCLIENTE",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "PRI_VLD_TIPO_TEXT",
        },
        {
          text: "Encomendante",
          align: "start",
          sortable: false,
          value: "DPE_NOM_PESSOA_LIG",
        },

        {
          text: "Câmbio aberto",
          align: "start",
          sortable: false,
          value: "CAMBIO_ABERTO",
        },
      ],
    };
  },

  //Load headers
  async mounted() {
    if (this.tab.dateField) {
      if (
        this.tab?.slug !== "contabil" &&
        this.tab?.slug !== "faturamento" &&
        this.tab?.slug !== "fechados"
      )
        this.headers.push({
          text: this.tab?.fieldLabel,
          align: "start",
          sortable: false,
          value: this.tab.dateField,
        });

      if (this.tab?.slug !== "fechados")
        this.headers.push({
          text: "Qtd dias aberto",
          align: "start",
          sortable: false,
          value: "daysToEvent",
        });
    }

    if (this.tab?.dateCaptureField) {
      this.headers.push({
        text: "Ações",
        align: "start",
        sortable: false,
        value: "action",
      });
    }
    if (this.tab?.userField) {
      this.headers.push({
        text: "Responsável",
        align: "start",
        sortable: false,
        value: this.tab?.userField,
      });
    }

    if (this.tab?.slug == "contabil") {
      this.headers.push({
        text: "Dt. Faturamento",
        align: "start",
        sortable: false,
        value: "DTA_EMISS_1SAIDA",
      });

      this.headers.push({
        text: "Dt. Finalização operacional",
        align: "start",
        sortable: false,
        value: "DTA_FIM_OPERACIONAL",
      });
    }

    if (this.tab?.slug == "faturamento") {
      this.headers.push({
        text: "Dt. Finalização contábil",
        align: "start",
        sortable: false,
        value: "DTA_FIM_CONTABIL",
      });
    }

    if (this.tab?.slug == "fechados") {
      this.headers.push({
        text: "Dt. Fech. Operacional",
        align: "start",
        sortable: false,
        value: "DTA_FECH_OPERACIONAL",
      });

      this.headers.push({
        text: "Dt. Fech. contábil",
        align: "start",
        sortable: false,
        value: "DTA_FECH_CONTABIL",
      });

      this.headers.push({
        text: "Dt. Fech. Financeiro",
        align: "start",
        sortable: false,
        value: "DTA_FECH_FINANCEIRO",
      });
    }

    if (
      this.tab?.slug == "contabil" ||
      this.tab?.slug == "operacional" ||
      this.tab?.slug == "financeiro" ||
      this.tab?.slug == "fechados"
    ) {
      this.headers.push({
        text: "Diferença",
        align: "start",
        sortable: false,
        value: "nota_debito",
      });
    }

    if (
      this.tab?.slug == "operacional" ||
      this.tab?.slug == "financeiro" ||
      this.tab?.slug == "faturamento" ||
      this.tab?.slug == "fechados" ||
      this.tab?.slug == "contabil"
    ) {
      this.headers.push({
        text: "Nota complementar",
        align: "start",
        sortable: false,
        value: "nota_complementar",
      });
    }
    this.headers.push({
      text: "Despesas",
      align: "start",
      sortable: false,
      value: "expenses",
    });
    //}

    await this.doSearch();
  },
  methods: {
    async exportToExcel() {
      let excelHeaders = this.headers.filter((el) => el.text !== "action");
      let itemsNotExport = ["id", "action", "expenses"];

      let data = this.items.map((el) => {
        let row = {};
        for (var i = 0; i < excelHeaders.length; i++) {
          let itemHeader = excelHeaders[i];
          //alert(JSON.stringify({ itemHeader }));
          if (itemHeader.text && itemHeader.value) {
            if (!itemsNotExport.find((el) => el == itemHeader.value)) {
              row[itemHeader.text] = el[itemHeader.value];
            }

            if (
              itemHeader.value?.includes("USER_") &&
              el[itemHeader.value]?.username
            ) {
              row[itemHeader.text] = el[itemHeader.value]?.username;
            }

            if (itemHeader?.value?.includes("nota_debito")) {
              row[itemHeader.text] = el.VL_NOTA_DEBITO;
            }

            if (itemHeader?.value?.includes("nota_complementar")) {
              row[itemHeader.text] = el.VL_NOTA_COMPLEMENTAR;
            }
            //Datas
            if (itemHeader?.value?.includes("DTA")) {
              row[itemHeader.text] = dateFormat(el[itemHeader.value]);
            }

            if (itemHeader?.value?.includes("daysToEvent")) {
              row[itemHeader.text] = this.daysToEvent(el[this.tab?.dateField]);
            }

            if (itemHeader?.value?.includes("CAMBIO_ABERTO")) {
              row[itemHeader.text] = el.VL_NOTA_COMPLEMENTAR ? "Sim" : "Não";
            }

            //
          }
        }

        return row;
      });
      console.log(JSON.stringify({ data }));
      const ws = utils.json_to_sheet(data);

      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Processos");

      writeFile(wb, "Processos.xlsx");
    },

    // getValue(item){
    //   if (itemHeader.value !== "id"){
    //           row[itemHeader.text] = el[itemHeader.value];

    //         }

    // },

    async doFinish(item) {
      let d = new Date();
      let processToUpdate = { id: item.id };
      let stages = [
        {
          id: 1,
          slug: "operacional",
        },
        {
          id: 2,
          slug: "contabil",
        },
        {
          id: 3,
          slug: "faturamento",
        },
        {
          id: 4,
          slug: "financeiro",
        },
        {
          id: 5,
          slug: "fechados",
        },
      ];

      //New version
      if (this.tab?.slug == "operacional") {
        // if (item.DTA_FIM_CONTABIL) {
        //   //alert("DTA_FIM_CONTABIL");
        // } else {
        //   processToUpdate.processStage = stages.find(
        //     (el) => el.slug == "contabil"
        //   )?.id;
        // }

        if (item.VL_NOTA_COMPLEMENTAR > 0) {
          processToUpdate.processStage = stages.find(
            (el) => el.slug == "faturamento"
          )?.id;
        } else {
          //Caso não tenha nota complementar continua na aba operacional
          processToUpdate.processStage = stages.find(
            (el) => el.slug == "operacional"
          )?.id;
        }
      }

      if (this.tab?.slug == "contabil") {
        if (item.VL_NOTA_COMPLEMENTAR > 0) {
          processToUpdate.processStage = stages.find(
            (el) => el.slug == "faturamento"
          )?.id;
        } else {
          processToUpdate.processStage = stages.find(
            (el) => el.slug == "operacional"
          )?.id;
        }
      }

      if (this.tab?.slug == "faturamento") {
        processToUpdate.processStage = stages.find(
          (el) => el.slug == "operacional"
        )?.id;
      }
      if (this.tab?.slug == "financeiro") {
        processToUpdate.processStage = stages.find(
          (el) => el.slug == "fechados"
        )?.id;
      }

      if (this.tab.dateClose)
        processToUpdate[this.tab.dateClose] = new Date().toISOString();
      let ans = await strapiApi.processes.move(
        processToUpdate,
        item,
        this.user?.id
      );

      this.showDialogFinish = false;
      this.doSearch();
    },

    async doEmailSent(item) {
      let d = new Date();
      let processToUpdate = { id: item.id };
      processToUpdate.DTA_EMAIL_SENT = d.toISOString();
      processToUpdate.USER_EMAIL_SENT = this.user?.id;
      processToUpdate.EMAIL_SENT = true;
      processToUpdate.processStage = {
        id: 4,
        slug: "financeiro",
      };

      let ans = await strapiApi.processes.move(
        processToUpdate,
        item,
        this.user?.id
      );
      this.showDialogEmail = false;
      this.doSearch();
    },

    openDialogFiles(item) {
      this.selectedProcess = item;
      this.showDialogFiles = true;
    },
    openDialogComments(item) {
      this.selectedProcess = item;
      this.showDialogComments = true;
    },

    openDialogMove(item) {
      this.itemSelected = item;
      this.showDialogMove = true;
    },
    showEmailButton(item) {
      return (
        this.tab?.slug == "operacional" &&
        ((!item.VL_NOTA_COMPLEMENTAR && item.DTA_FIM_OPERACIONAL) ||
          (item.VL_NOTA_COMPLEMENTAR > 0 && item.DTA_FIM_FATURAMENTO))
      );
    },
    getTipoProcessoText(PRI_VLD_TIPO) {
      if (PRI_VLD_TIPO == 1) {
        return "CONTA PRÓPRIA";
      }
      if (PRI_VLD_TIPO == 2) {
        return "CONTA ORDEM DE TERCEIROS";
      }
      if (PRI_VLD_TIPO == 3) {
        return "ENCOMENDA";
      }
      return "";
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    openDialog(item) {
      this.itemSelected = item;

      this.showDialog = true;
    },

    openDialogEdit(item) {
      this.itemSelected = item;

      this.showDialogEdit = true;
    },

    openDialogEditDebito(item) {
      this.itemSelected = item;
      this.tipoNota = "DEBITO";
      this.showDialogEdit = true;
    },

    openDialogEditComplementar(item) {
      this.itemSelected = item;
      this.tipoNota = "COMPLEMENTAR";
      this.showDialogEdit = true;
    },

    openDialogFinish(item) {
      this.itemSelected = item;

      this.showDialogFinish = true;
    },

    openDialogRefuse(item) {
      this.itemSelected = item;

      this.showDialogRefuse = true;
    },

    openDialogEmail(item) {
      this.itemSelected = item;

      this.showDialogEmail = true;
    },

    async openDialogReport(item) {
      this.itemSelected = item;
      this.showDialogReport = true;
      let d = new Date();
      this.lastRefreshExpenses = d.getTime();
    },

    async openDialogUserLogs(item) {
      this.itemSelected = item;
      this.showDialogUserLogs = true;
    },

    async doCapture(item) {
      let d = new Date();
      let processToUpdate = { ...item };
      processToUpdate[this.tab?.dateCaptureField] = d.toISOString();
      processToUpdate[this.tab?.userField] = this.user?.id;
      if (this.tab?.slug == "contabil") {
        processToUpdate.VL_NOTA_FISCAL = this.itemSelected.VL_NOTA_FISCAL;
        if (this.itemSelected.TIPO_NOTA_FISCAL)
          processToUpdate.TIPO_NOTA_FISCAL = this.itemSelected.TIPO_NOTA_FISCAL;
      }
      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialog = false;
      this.doSearch();
    },

    async doMove(item) {
      let d = new Date();
      let data = { ...item };

      // processNew: { ...item, processStage: stageTo },
      //     processOld: item,
      //     userId: this.user.id,
      let ans = await strapiApi.processes.move(
        data.processNew,
        data.processOld,
        data.userId
      );
      console.log(JSON.stringify({ ans }));

      let newComment = {
        value: data.comment,
        process: data.processNew.id,
        user: this.user.id,
        processStageLog: ans.id,
      };

      //console.log(JSON.stringify({ newComment }));

      let ansComment = await strapiApi.processComments.post(newComment);

      console.log(JSON.stringify({ ansComment }));

      this.showDialogMove = false;
      this.doSearch();
    },

    async doEdit(item) {
      if (
        this.tab?.slug == "faturamento" &&
        (!item.VL_NOTA_FISCAL || !item.TIPO_NOTA_FISCAL)
      ) {
        this.message = "Preencher valor da nota e tipo de nota fiscal";

        return;
      }

      let d = new Date();
      let processToUpdate = { ...item };
      processToUpdate[this.tab?.dateCaptureField] = d.toISOString();
      processToUpdate[this.tab?.userField] = this.user?.id;

      if (this.tab?.slug == "faturamento") {
        processToUpdate.VL_NOTA_FISCAL = item.VL_NOTA_FISCAL;
        processToUpdate.TIPO_NOTA_FISCAL = item.TIPO_NOTA_FISCAL;
      }

      if (item?.NF_EMITIDA) {
        processToUpdate.DTA_FECH_FINAL = d.toISOString();
      }
      let ans = await strapiApi.processes.put(processToUpdate);
      this.showDialogEdit = false;

      this.doSearch();
    },

    daysToEvent(dateEventString) {
      let dateEvent = new Date(dateEventString);
      let today = new Date();

      let diff =
        (today.getTime() - dateEvent.getTime()) / (24 * 60 * 60 * 1000);
      return Math.floor(diff);
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      this.loading = true;
      let filters = { ...this.filtersParent };
      let filterToAdd;

      if (!filters.and) filters.and = [];

      //Stage id
      if (this.tab.id && this.filterStatus !== 3) {
        filters.processStage = {
          id: { eq: this.tab.id },
        };
      }

      if (this.filterStatus) {
        //Pendente
        if (this.filterStatus == 1) {
          filters[this.tab.dateCaptureField] = { null: true };
        }

        //Capturados
        if (this.filterStatus == 2) {
          filters[this.tab?.dateCaptureField] = { gt: dateStart };
        }

        //Finalizados
        if (this.filterStatus == 3 && this.tab?.dateClose) {
          filters[this.tab?.dateClose] = { gt: dateStart };
        }

        if (this.filterStatus == 4) {
          filters.and.push({
            or: [{ EMAIL_SENT: { eq: false } }, { EMAIL_SENT: { null: true } }],
          });
        }
      }

      //Filter Owner
      if (
        this.filterUserId &&
        this.tab?.userField &&
        (this.filterStatus == 2 || this.filterStatus == 3)
      ) {
        filters[this.tab?.userField] = {
          id: { eq: this.filterUserId },
        };
      }

      this.filtersActive = filters;

      let { values, pagination } =
        await strapiApi.processes.getProcessosFechamento(
          this.page,
          this.itemsPerPage,
          this.tab?.dateField,
          false,
          filters
        );

      this.items = values.map((el) => ({
        ...el,
        PRI_VLD_TIPO_TEXT: this.getTipoProcessoText(el.PRI_VLD_TIPO),
        //  DTA_EMISS_1SAIDA_formatted: dateFormat(el.DTA_EMISS_1SAIDA),
        //: moneyFormat(el.VL_NOTA_DEBITO),
      }));

      this.serverItemsLength = pagination?.total;

      this.loading = false;
    },
  },
};
</script>

<style></style>
