<template>
  <v-container fluid>
    <v-container v-if="user?.role?.id == 6" fluid>
      <p>Processos em alerta</p>

      <v-data-table
        :headers="processesHeaders"
        :items="processes"
        item-key="id"
        ref="dataTable"
        hide-default-footer
      >
        <template v-slot:item.dta_chegada_bl="{ item }">
          <span v-date-format="item.dta_chegada_bl"></span>
        </template>
      </v-data-table>
      <!-- 
      {{ JSON.stringify(processes) }} -->
    </v-container>
    <v-container v-else>
      <p>Acesso não autorizado</p>
    </v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "Alerts",
  props: ["priCod"],
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    filCod() {
      return this.$route.query.filCod;
    },
  },
  data() {
    return {
      processes: [],
      processesHeaders: [
        {
          text: "Referência interna",
          value: "pri_esp_refcliente",
          width: "20px",
          sortable: false,
        },
        {
          text: "Pessoa ligada ao processo",
          value: "dpe_nom_pessoa_lig",
          width: "20px",
          sortable: false,
        },
        {
          text: "Descrição regime aduaneiro",
          value: "itd_des_nome",
          width: "20px",
          sortable: false,
        },
        {
          text: "Data de chegada",
          value: "dta_chegada_bl",
          width: "20px",
          sortable: false,
        },
        {
          text: "Dias desde a chegada",
          value: "dias_desde_chegada",
          width: "20px",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.getProcesses();
  },
  methods: {
    async getProcesses() {
      let ans = await strapiApi.processes.getWarehouseAlerts();
      this.processes = ans?.processes;
    },
  },
};
</script>

<style scoped>
.tabActive {
  background-color: #3e8ac7;
}

.holds-the-iframe {
  background: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif")
    center center no-repeat;
}

.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}
</style>
