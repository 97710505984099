<template>
  <v-container fluid class="pa-10">
    <br />
    <h1>Integrações</h1>
    <br />
    <!-- ADMIN -->
    <v-container
      v-if="user?.role?.id == roleIds.admin || user.acessoIntegracoes"
      fluid
    >
      <p>Lançamentos contabilidade</p>
      <div
        style="
          display: flex;
          justify-content: start;

          width: 500px;
        "
      >
        <v-select
          style="width: 200px"
          :items="filiais"
          label="Filial"
          placeholder="Acesso"
          persistent-placeholder
          clearable
          v-model="selectedFilial"
        ></v-select>

        <!-- <p>Selecione a data do lote</p> -->
        <!-- <v-date-picker
          v-model="dateFilterStart"
          type="date"
          no-title
          @input="menu = false"
          reactive
          scrollable
          color="actionButton"
        ></v-date-picker> -->
        <DateInput v-model="anomesDate" label="Mês de referência"></DateInput>
        <DateInputDate
          v-model="lastDate"
          label="Data do lote"
          type="date"
        ></DateInputDate>

        <v-btn :disabled="loading" @click="ctb(selectedFilial)" class="primary"
          >Integrar

          <v-progress-circular
            v-show="loading"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </div>

      <br />

      <div>
        <p>Status da Integração.</p>
        <!-- <div style="background-color: lightslategray">
          <p>{{ ans }}</p>
        </div> -->
        <!-- <v-textarea
          fluid
          style="background-color: lightgray; width: 600px; height: 600px"
          v-model="ans"
        ></v-textarea> -->

        <!-- <p>Ans</p> -->
        <textarea
          v-if="ans"
          v-model="ans"
          style="width: 100%; height: 600px; background-color: lightgray"
        >
        </textarea>
      </div>
    </v-container>

    <v-container v-else>
      <p>Acesso não autorizado</p>
    </v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import roleIds from "../../../../libraries/strapi/rolesIds";
import stmarieApi from "../../../../libraries/stmarie/stmarieApi";
import DateInputDate from "../../components/fields/DateInputDate";
import DateInput from "../../components/fields/DateInput";
import { parse } from "vue-currency-input";

export default {
  name: "Integrations",
  props: ["priCod"],
  components: { DateInputDate, DateInput },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    filCod() {
      return this.$route.query.filCod;
    },
  },
  data() {
    return {
      filiais: ["01", "02", "03", "07", "20", "21", "22"],
      selectedFilial: "01",
      lastDate: "", //LastDate
      anomesDate: "", //Mes de refeência
      ans: ``,
      roleIds: roleIds,
      loading: false,
    };
  },
  async mounted() {
    this.lastDate = new Date().toISOString();
    this.anomesDate = new Date().toISOString().substring(0, 7);
    //  alert(this.anomesDate);
  },
  methods: {
    async getProcesses() {},

    async ctb(filial) {
      this.ans = "";
      this.loading = true;

      if (this.lastDate && this.selectedFilial && this.anomesDate) {
        let d = new Date(this.lastDate?.substring(0, 10));

        // alert(d);
        // alert(this.anomesDate.substring(0, 4));
        // alert(this.anomesDate.substring(5, 7));
        let year = this.anomesDate.substring(0, 4);
        let month = this.anomesDate.substring(5, 7);
        let anomes = `${year}${month}`;
        // alert(JSON.stringify({ year, month, anomes }));

        let d2 = new Date();

        try {
          let ans = await stmarieApi.ctbV2(
            filial,
            parseInt(anomes),
            d.getTime()
          );
          this.ans = JSON.stringify(ans?.saida, null, 2);
        } catch (error) {
          this.ans = error;
        }
      } else {
        alert("Preencher todos os campos");
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.tabActive {
  background-color: #3e8ac7;
}

.holds-the-iframe {
  background: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif")
    center center no-repeat;
}

.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}
</style>
