<template>
  <v-dialog v-model="showDialog" width="600px">
    <v-card>
      <v-toolbar class="primary dark">
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="showDialog = false"> Fechar </v-btn>
      </v-toolbar>
      <v-card-title>
        <span class="headline">Capturar</span>
      </v-card-title>

      <v-card-text class="">
        Confirmar captura do processo {{ itemSelected?.PRI_ESP_REFCLIENTE }}
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="green"
          color="white"
          text
          @click="doCapture(itemSelected)"
        >
          Sim
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="red" color="white" text @click="showDialog = false">
          Não
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "dialog-capture",
  props: ["value", "itemSelected"],
  data() {
    return {
      files: [],
      selectedFile: null,
      base64Data: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.showDialog = false;
    },

    async doCapture(item) {
      this.$emit("doCapture", item);
    },
  },
  watch: {
    showDialog(val) {
      //  if (val) this.fetchFiles();
    },
  },
};
</script>
