<template>
  <v-dialog v-model="showDialog" width="600px">
    <v-card>
      <v-toolbar class="primary dark">
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="showDialog = false"> Fechar </v-btn>
      </v-toolbar>
      <v-card-title>
        <span class="headline">Encaminhar</span>
      </v-card-title>

      <v-card-text class="">
        Encaminhar processo
        <b style="font-weight: bolder">{{
          itemSelected?.PRI_ESP_REFCLIENTE
        }}</b>
        para a etapa:
      </v-card-text>

      <v-card-text>
        <v-textarea label="Comentário" v-model="comment"></v-textarea>
      </v-card-text>

      <v-card-text>
        <v-select
          :items="tabs.map((el) => ({ value: el.id, text: el.title }))"
          label="Direcionar para a etapa"
          v-model="stageToId"
        ></v-select>
      </v-card-text>
      <!-- <v-card-text>
        {{ JSON.stringify({ itemSelected }) }}
      </v-card-text> -->

      <v-card-actions>
        <v-btn class="green" color="white" text @click="doMove(itemSelected)">
          Sim
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="red" color="white" text @click="showDialog = false">
          Não
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "dialog-move",
  props: ["value", "itemSelected", "tabs"],
  data() {
    return {
      files: [],
      selectedFile: null,
      base64Data: "",
      loading: false,
      stageToId: 0,
      comment: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.showDialog = false;
    },

    async doMove(item) {
      if (!this.comment) {
        alert("Preencher comentário");
        return;
      }
      // alert(this.stageToId);
      if (this.stageToId) {
        let stageTo = this.tabs.find((el) => el.id == this.stageToId);
        // alert(JSON.stringify({ stageTo }));

        let processToUpdate = {
          processNew: { ...item, processStage: stageTo },
          processOld: item,
          userId: this.user.id,
          comment: this.comment,
        };

        processToUpdate.processNew[stageTo.dateClose] = null;

        // processToUpdate[stageTo.dateCaptureField] = null;
        this.$emit("doMove", processToUpdate);
      } else {
        alert("Selecionar etapa de destino");
      }
      //this.$emit("doMove", item);
    },
  },
  watch: {
    showDialog(val) {
      //  if (val) this.fetchFiles();
    },
  },
};
</script>
