import functions from "./functions";
var processStageLogs = {
  async getWithPagination(page, pageSize, sort, sortDesc, processId) {
    const start = Date.now();

    var filters = {};

    if (processId) {
      filters = {
        process: {
          id: { eq: processId },
        },
      };
    }

    var query = `query processStageLogs($filters:ProcessStageLogFiltersInput){
        processStageLogs( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
            data{
              id
              attributes{
                createdAt
                user{
                  data{
                    id
                    attributes{
                      username
                    }
                  }
                }
                processStageTo{
                  data{
                    id
                    attributes{
                      name
                    }
                  }
                }
                processStageFrom{
                  data{
                    id
                    attributes{
                      name
                    }
                  }
                }
                processComment{
                  data{
                    id
                    attributes{
                       value
                    }
                  }
                }
              }
            }
        }
      }
    `;
    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination processStageLogs time: ${
          (end - start) / 1000
        } s`
      );

    const pagination = res?.data?.processStageLogs?.meta?.pagination;
    const values = functions.sanitize(res.data.processStageLogs.data);
    return { values, pagination };
  },
};

export default processStageLogs;
