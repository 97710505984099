<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card fluid>
      <v-toolbar class="primary dark">
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="dialog = false"> Fechar </v-btn>
      </v-toolbar>
      <v-card-title>
        <span class="headline">Usuários responsáveis e logs</span>
      </v-card-title>

      <v-card-text class="">
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th>Atividade</th>
                <th class="subheading font-weight-light text-center">
                  Usuário
                </th>
                <th class="subheading font-weight-light text-center">
                  Data captura
                </th>
                <th class="subheading font-weight-light text-center">
                  Data finalização
                </th>
              </tr>
            </thead>

            <tbody class="text-center">
              <tr
                v-for="(userTab, index) in tabs.filter(
                  (el) => el.slug !== 'fechados'
                )"
                :key="index"
              >
                <th class="text-left font-weight-light subtitle-1">
                  {{ userTab.title }}
                </th>
                <th class="text-left font-weight-light subtitle-1">
                  {{ itemSelected[userTab.userField]?.username ?? "" }}
                </th>

                <th class="text-left font-weight-light subtitle-1">
                  <span
                    v-date-format="itemSelected[userTab.dateCaptureField]"
                  ></span>
                </th>

                <th class="text-left font-weight-light subtitle-1">
                  <span v-date-format="itemSelected[userTab.dateClose]"></span>
                </th>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-card-text>

      <v-card-text>
        <v-card>
          <v-progress-circular
            v-show="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-timeline>
            <v-timeline-item v-for="(log, n) in logs" :key="n" large>
              <template v-slot:icon>
                <v-avatar>
                  <!-- <img src="https://i.pravatar.cc/64" /> -->
                  <h3 style="color: white">
                    {{ log?.user?.username?.[0] }}
                  </h3>
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span>{{ log?.user?.username }}</span>
                <p v-datetime-format="log.createdAt"></p>
              </template>
              <v-card class="elevation-2">
                <v-card-text
                  >{{ log.processStageFrom?.name }}
                  {{ `${log.processStageFrom?.name ? "->" : ""}` }}
                  {{ log.processStageTo?.name }}</v-card-text
                >
                <v-card-text v-if="log.processComment?.value">
                  Obs:{{ log.processComment?.value }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import functions from "../../../../libraries/strapi/functions";
import axios from "axios";
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "DialogUserLogs",
  props: ["value", "tabs", "itemSelected"],
  data() {
    return {
      logs: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getData() {
      console.log("get data");
      this.loading = true;
      let { values, pagination } =
        await strapiApi.processStageLogs.getWithPagination(
          1,
          25,
          "createdAt",
          false,
          this.itemSelected.id
        );

      this.logs = values;

      this.loading = false;
      console.log(JSON.stringify({ values, pagination }));
    },
  },
  watch: {
    dialog(val) {
      //alert("get data");
      if (val) {
        this.getData();
      }
    },
  },
};
</script>
