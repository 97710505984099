<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-toolbar class="primary" dark
        >Arquivos
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
      >

      <v-card-text>
        <table
          style="
            width: 100%;
            border-collapse: collapse;
            border: 1px solid black;
          "
        >
          <v-progress-circular
            v-show="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <thead>
            <tr style="border: 1px solid black; background-color: #f5f5f5">
              <th style="border: 1px solid black; padding: 8px">Arquivo</th>
              <th style="border: 1px solid black; padding: 8px">Criador</th>
              <th style="border: 1px solid black; padding: 8px">Data</th>
              <th style="border: 1px solid black; padding: 8px">Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="file in files"
              :key="file.id"
              style="border: 1px solid black"
            >
              <td style="border: 1px solid black; padding: 8px">
                <a
                  :href="'data:application/octet-stream;base64,' + file.base64"
                  :download="file.filename"
                >
                  {{ file.filename }}</a
                >
              </td>
              <td style="border: 1px solid black; padding: 8px">
                <span>{{ file?.creator?.username }}</span>
              </td>
              <td style="border: 1px solid black; padding: 8px">
                <span v-datetime-format="file.createdAt"></span>
              </td>
              <td style="border: 1px solid black; padding: 8px">
                <!-- <span>{{ file.id }}</span>
                <span> creator {{ file?.creator?.id }}</span>
                <span>, user{{ user?.id }}</span> -->
                <v-tooltip v-if="user?.id == file?.creator?.id" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      @click="deleteProcessFile(file)"
                      >mdi-delete-outline
                    </v-icon>
                  </template>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <hr />
        <br />
        <v-file-input
          v-model="selectedFile"
          label="Upload File"
          outlined
          @change="handleFileUpload"
        ></v-file-input>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="uploadFile">Upload</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import functions from "../../../../libraries/strapi/functions";
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: ["value", "processId", "process"],
  data() {
    return {
      files: [],
      selectedFile: null,
      base64Data: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.dialog = false;
    },
    async fetchFiles() {
      this.loading = true;
      this.files = [];
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_URL}/api/process-files?filters[process][id][$eq]=${this.process?.id}&populate=creator`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );

        let files = functions.sanitize(response.data.data);

        this.files = files;
      } catch (error) {
        console.error("Error fetching files:", error);
      }
      this.loading = false;
    },
    handleFileUpload(event) {
      const file = event;
      if (!file) return;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.base64Data = reader.result.split(",")[1]; // Remove `data:...;base64,` prefix
      };
      reader.onerror = (error) => console.error("Error reading file:", error);
    },
    async uploadFile() {
      if (!this.base64Data || !this.selectedFile?.name) {
        alert("Selecionar arquivo");
        return;
      }
      //alert(this.selectedFile?.name);
      try {
        await axios.post(
          `${process.env.VUE_APP_STRAPI_URL}/api/process-files`,
          {
            data: {
              filename: this.selectedFile.name,
              base64: this.base64Data,
              process: this.process?.id,
              creator: this.user.id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );
        await this.fetchFiles(); // Refresh file list
        this.selectedFile = [];
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },

    async deleteProcessFile(processFile) {
      //7 alert(processFile.id);
      //return;
      try {
        await axios.delete(
          `${process.env.VUE_APP_STRAPI_URL}/api/process-files/${processFile.id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );
        await this.fetchFiles(); // Refresh file list
        this.selectedFile = [];
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) this.fetchFiles();
    },
  },
};
</script>
